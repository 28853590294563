<template>
  <sticky-page>
    <div
      class="card card-style preload-img"
      data-src="images/pictures/8w.jpg"
      data-card-height="130"
    >
      <div class="card-center ml-3">
        <h1 class="color-white mb-0">Get in Touch</h1>
        <p class="color-white mt-n1 mb-0">We're always here for you!</p>
      </div>
      <div class="card-center mr-3">
        <router-link
          to="/"
          class="btn btn-m float-right rounded-xl shadow-xl text-uppercase font-800 bg-highlight"
          >Back Home</router-link
        >
      </div>
      <div class="card-overlay bg-black opacity-80"></div>
    </div>
    <div class="card card-style contact-form">
      <div class="content">
        <form          
          class="contactForm"
          id="contactForm"
        >
          <fieldset>
            <div class="form-field form-text">
              <label
                class="contactMessageTextarea color-theme"
                for="contactMessageTextarea"
                >Message:<span>(required)</span></label
              >
              <textarea
                name="message"
                class="contactTextarea round-small requiredField"
                id="contactMessageTextarea"
                v-model="message"
              ></textarea>
            </div>
            <div class="form-button">
              <input
                @click="submit"
                class="btn bg-highlight text-uppercase font-900 btn-m btn-full rounded-sm shadow-xl contactSubmitButton"
                value="Send Message"
                data-formId="contactForm"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
//import $ from "jquery";
import { Options, Vue } from "vue-class-component";
import ApiService from "@/services/apiService";
import StickyPage from "@/components/sticky-page.vue";
//import { UserService, WWUser } from "../services/userService";
import { WWUser, DefaultUser, UUserService } from "@/shared/user";

@Options({
  components: {
    StickyPage,
  },
})
export default class ContactView extends Vue {
  private formAction!: string;
  private name = "";
  private email = "";
  private message = "";
  private api = new ApiService();

  private async submit() {
    const msg = { message: this.message, email: this.email, name: this.name };
    const result = await this.api.queueItem("messages", msg);
    //TODO: show result to user
  }
  public async mounted() {
    window.ww.theme.initAll();
  }
}
</script>