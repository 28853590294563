<template>
  <sticky-page>
    <div class="card card-style">
      <div class="content">
        <h4 class="mb-0">Terms of Service</h4>
        <p class="mb-0 color-highlight font-12 bottom-10">
          Modified on 01 December 2020
        </p>
        <!-- <p>
                    This is just dummy text. You can update it to suit your terms and conditions from your page.
                </p> -->
      </div>
    </div>

    <div class="card card-style">
      <div class="content">
        <h2 class="">1. Terms</h2>
        <p>
          By accessing this web site, you are agreeing to be bound by these web
          site Terms and Conditions of Use, all applicable laws and regulations,
          and agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site. The materials contained
          in this web site are protected by applicable copyright and trade mark
          law.
        </p>

        <h4 class="">2. Use License</h4>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on Word Warden's web site for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:

            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Word Warden's web site;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by Word Warden at any time.
            Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </li>
        </ol>

        <h4 class="">3. Disclaimer</h4>
        <ol type="a">
          <li>
            The materials on Word Warden's web site are provided "as is". Word
            Warden makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further, Word
            Warden does not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the materials
            on its Internet web site or otherwise relating to such materials or
            on any sites linked to this site.
          </li>
        </ol>

        <h4 class="">4. Limitations</h4>
        <p>
          In no event shall Word Warden or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption,) arising out of the use or
          inability to use the materials on Word Warden's Internet site, even if
          Word Warden or a Word Warden authorized representative has been
          notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>

        <h4 class="">5. Revisions and Errata</h4>
        <p>
          The materials appearing on Word Warden's web site could include
          technical, typographical, or photographic errors. Word Warden does not
          warrant that any of the materials on its web site are accurate,
          complete, or current. Word Warden may make changes to the materials
          contained on its web site at any time without notice. Word Warden does
          not, however, make any commitment to update the materials.
        </p>

        <h4 class="">6. Links</h4>
        <p>
          Word Warden has not reviewed all of the sites linked to its Internet
          web site and is not responsible for the contents of any such linked
          site. The inclusion of any link does not imply endorsement by Word
          Warden of the site. Use of any such linked web site is at the user's
          own risk.
        </p>

        <h4 class="">7. Site Terms of Use Modifications</h4>
        <p>
          Word Warden may revise these terms of use for its web site at any time
          without notice. By using this web site you are agreeing to be bound by
          the then current version of these Terms and Conditions of Use.
        </p>

        <h4 class="">8. Governing Law</h4>
        <p class="">
          Any claim relating to Word Warden's web site shall be governed by the
          laws of the State of Minnesota, United States without regard to its
          conflict of law provisions.
        </p>
      </div>
    </div>
  </sticky-page>
  <!-- End of Page Content-->
</template>

<script lang="ts">
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
// @ is an alias to /src
@Options({
  name: "TermsView",
  components: { StickyPage },
})
export default class TermsView extends Vue {
}
</script>
