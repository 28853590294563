
//import $ from "jquery";
import { Options, Vue } from "vue-class-component";
import ApiService from "@/services/apiService";
import StickyPage from "@/components/sticky-page.vue";
//import { UserService, WWUser } from "../services/userService";
import { WWUser, DefaultUser, UUserService } from "@/shared/user";

@Options({
  components: {
    StickyPage,
  },
})
export default class ContactView extends Vue {
  private formAction!: string;
  private name = "";
  private email = "";
  private message = "";
  private api = new ApiService();

  private async submit() {
    const msg = { message: this.message, email: this.email, name: this.name };
    const result = await this.api.queueItem("messages", msg);
    //TODO: show result to user
  }
  public async mounted() {
    window.ww.theme.initAll();
  }
}
