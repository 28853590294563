
import StickyPage from "@/components/sticky-page.vue";
import StickyToggle from "@/components/sticky/sticky-toggle.vue";
import { ProfileService, ProfileSettings } from "@/services/profileService";
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  components: {
    StickyPage,
    StickyToggle,
  },
  data() {
    return {
      currentSettings: new ProfileSettings(),
      ps: new ProfileService(),
    };
  },

  methods: {
    async geo(val: boolean) {
      this.currentSettings.project.useGeoposition = val;
      if (val) {
        const result = await this.tryGetGeoPermission();
        if (!result) {
          this.currentSettings.project.useGeoposition = false;
        }
      }
    },
    async pause(val: boolean) {
      this.currentSettings.project.allowPause = val;
    },
    async dark(val: boolean) {
      this.currentSettings.theme.darkMode = val;
    },
    async bug(val: boolean) {
      this.currentSettings.general.debugMode = val;
    },
    tryGetGeoPermission(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          resolve(false);
        }

        const tryAndSee = () => {
          try {
            navigator.geolocation.getCurrentPosition(
              (pos) => {
                resolve(true);
              },
              (e) => resolve(false)
            );
          } catch (e) {
            resolve(false);
          }
        };

        if (navigator.permissions) {
          // Permission API is implemented
          const permission = navigator.permissions
            .query({
              name: "geolocation",
            })
            .then((permission) => {
              if (permission.state === "granted") {
                resolve(true);
              } else {
                tryAndSee();
              }
            });
        } else {
          // Permission API was not implemented
          tryAndSee();
        }
      });
    },
    notifyMe() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        const notification = new Notification("Hi there!", {
          timestamp: Date.now() + 5000,
        });
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            const notification = new Notification("Hi there!", {
              timestamp: Date.now() + 5000,
            });
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them any more.
    },
  },
  watch: {
    currentSettings: async function (
      val: ProfileSettings,
      oldVal: ProfileSettings
    ) {
      if (val && oldVal) {
        await this.ps.replaceSettings(val);
      }
    },
  },
  async mounted() {
    this.currentSettings = await this.ps.getCurrentSettings(); //created not async / needs to be here
  },
});
