
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
// @ is an alias to /src
@Options({
  name: "TermsView",
  components: { StickyPage },
})
export default class TermsView extends Vue {
}
